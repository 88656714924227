import { getConfig } from "@src/config";
import { GQLFinanceInformationParameter } from "@src/graphql.apollo.generated";
import React from "react";

import InfoIcon from "../../infoIcon/InfoIcon";
import { ColumnsOrder } from "../FinCalcLightboxGroup";
import { Column, ColumnWrapper, Error, InfoIconWrapper, Unit } from "../formStyles.sc";

interface LabelProps {
    parameter: GQLFinanceInformationParameter;
    bold?: boolean;
    loading?: boolean;
    columnsOrder?: ColumnsOrder;
}

const Label: React.FC<LabelProps> = ({ parameter, bold, columnsOrder = "label-unit-value" }) => {
    const UnitContainer = (
        <Column
            bold={bold ?? parameter.bold}
            largeText={bold ?? (parameter.bold && parameter.dataType === "Decimal")}
            hasError={parameter.error != null}
        >
            <Unit>{parameter.unit}</Unit>
        </Column>
    );

    const ValueContainer = (
        <>
            <Column
                columnsOrder={columnsOrder}
                bold={bold ?? parameter.bold}
                largeText={bold ?? (parameter.bold && parameter.dataType === "Decimal")}
                hasError={parameter.error !== null}
            >
                {parameter.value}
                {(parameter.id === "Rate" || parameter.id === "TotalRateSum") && getConfig("scope").domain !== "car4me.si" && <div>**</div>}
            </Column>
            {parameter.help && (
                <InfoIconWrapper>
                    <InfoIcon alignment="right" infoText={<div dangerouslySetInnerHTML={{ __html: parameter.help }} />} isClickable />
                </InfoIconWrapper>
            )}
        </>
    );

    return (
        <>
            <div>
                <Column bold={bold ?? parameter.bold} largeText={bold ?? (parameter.bold && parameter.dataType === "Decimal")}>
                    {parameter.label}
                </Column>
                {parameter.error?.description && <Error dangerouslySetInnerHTML={{ __html: parameter.error?.description ?? "" }} />}
            </div>

            {columnsOrder === "label-unit-value" ? (
                <ColumnWrapper>
                    {parameter.unit && UnitContainer}
                    {ValueContainer}
                </ColumnWrapper>
            ) : (
                <>
                    {ValueContainer}
                    {parameter.unit && UnitContainer}
                </>
            )}
        </>
    );
};

export default Label;
