import { GQLFinanceInformationParameter } from "@src/graphql.apollo.generated";
import React from "react";
import { Field } from "react-final-form";
import { FormattedNumber, useIntl } from "react-intl";

import InfoIcon from "../../infoIcon/InfoIcon";
import { ColumnsOrder } from "../FinCalcLightboxGroup";
import { Column, Error, InfoIconWrapper, Root, Row, SelectContainerWrapper, Unit, UnitLabel } from "../formStyles.sc";
import { RadioInput } from "./RadioInput";

interface ListProps {
    parameter: GQLFinanceInformationParameter;
    columnsOrder?: ColumnsOrder;
}

const List: React.FC<ListProps> = ({ parameter, columnsOrder = "label-unit-value" }) => {
    const intl = useIntl();

    const options = [
        { value: "true", label: intl.formatMessage({ id: "leaseme.yes", defaultMessage: "Ja" }) },
        { value: "false", label: intl.formatMessage({ id: "leaseme.no", defaultMessage: "Nein" }) },
    ];

    return (
        <Root key={parameter.id}>
            <Row>
                <Column>{parameter.label}</Column>
                <Column>
                    <UnitLabel columnsOrder={columnsOrder}>
                        {parameter.unit && <Unit columnsOrder={columnsOrder}>{parameter.unit}</Unit>}
                        <FormattedNumber value={Number(parameter.defaultOption)} minimumFractionDigits={2} />
                    </UnitLabel>
                </Column>
            </Row>
            <Row>
                <Column isFullWidth>
                    <SelectContainerWrapper flexStyle="row" selectStyle="radio">
                        {parameter.id &&
                            parameter.value &&
                            options &&
                            options.map((option) => (
                                <Field
                                    key={option.label}
                                    name={parameter.id ?? ""}
                                    component={RadioInput}
                                    type="radio"
                                    value={option.value}
                                    defaultValue={parameter.value}
                                    id={parameter.id + option.value}
                                    width="50%"
                                >
                                    {option.label}
                                </Field>
                            ))}
                    </SelectContainerWrapper>
                    {parameter.help && (
                        <InfoIconWrapper>
                            <InfoIcon alignment="right" infoText={<div dangerouslySetInnerHTML={{ __html: parameter.help ?? "" }} />} isClickable />
                        </InfoIconWrapper>
                    )}
                </Column>
            </Row>
            <Error dangerouslySetInnerHTML={{ __html: parameter.error?.description ?? "" }} />
        </Root>
    );
};

export default List;
