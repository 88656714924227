import { getConfig } from "@src/config";
import { GQLFinanceInformationParameter } from "@src/graphql.apollo.generated";
import { useFinanceRuntimeOptions } from "@src/shared/context/searchfilter/Options";
import { DefaultReactSelectStyle } from "@src/shared/searchResults/searchfilter/filter/ReactSelectStyle";
import React from "react";
import { Field } from "react-final-form";
import ReactSelect from "react-select";

import InfoIcon from "../../infoIcon/InfoIcon";
import { ColumnsOrder } from "../FinCalcLightboxGroup";
import { Column, ColumnWrapper, Error, InfoIconWrapper, SelectContainerWrapper, Unit } from "../formStyles.sc";

interface SelectProps {
    parameter: GQLFinanceInformationParameter;
    columnsOrder?: ColumnsOrder;
}

const Select: React.FC<SelectProps> = ({ parameter, columnsOrder = "label-unit-value" }) => {
    const financeRuntimeOptions = useFinanceRuntimeOptions(getConfig("scope").domain);
    let options = parameter.options ?? undefined;

    if (parameter.id === "Duration") {
        options = parameter.options?.filter((opt) => financeRuntimeOptions.find((runtime) => runtime.value === Number(opt.value)));
    }

    if (parameter.id === "ResidualValuePercentage") {
        options = parameter.options?.map((option) => ({ label: option.label, value: Number(option.value).toString() }));
    }

    const ValueContainer = (
        <Column columnsOrder={columnsOrder}>
            <SelectContainerWrapper>
                {parameter.defaultOption && parameter.id && (
                    <Field
                        name={parameter.id}
                        initialValue={parameter.defaultOption}
                        render={({ input }) =>
                            options && (
                                <ReactSelect
                                    {...input}
                                    onChange={(option: { value: any; label: string }) => input.onChange(option ? option.value : "")}
                                    options={options}
                                    isSearchable={false}
                                    classNamePrefix={"react-select"}
                                    value={options.filter((option) => {
                                        if (parameter.id === "ResidualValuePercentage") {
                                            return option.value === Number(input.value).toString();
                                        }

                                        return option.value === input.value;
                                    })}
                                    styles={DefaultReactSelectStyle}
                                    isLightbox
                                />
                            )
                        }
                    />
                )}
            </SelectContainerWrapper>
            {parameter.help && (
                <InfoIconWrapper>
                    <InfoIcon alignment="right" infoText={<div dangerouslySetInnerHTML={{ __html: parameter.help }} />} isClickable />
                </InfoIconWrapper>
            )}
        </Column>
    );

    const UnitContainer = <Unit>{parameter.unit}</Unit>;

    return (
        <>
            <div>
                <Column>{parameter.label}</Column>
                {parameter.error?.description && <Error dangerouslySetInnerHTML={{ __html: parameter.error?.description ?? "" }} />}
            </div>

            {columnsOrder === "label-unit-value" ? (
                <ColumnWrapper>
                    {parameter.unit && UnitContainer}
                    {ValueContainer}
                </ColumnWrapper>
            ) : (
                <>
                    {ValueContainer}
                    {parameter.unit && UnitContainer}
                </>
            )}
        </>
    );
};

export default Select;
