import { getConfig } from "@src/config";
import { GQLFinanceInformationGroupFragmentFragment, GQLFinanceInformationParameterFragmentFragment } from "@src/graphql.apollo.generated";
import React from "react";

import { useStoredState } from "../hooks/useStoredState";
import Dropdown from "../vehicleDetail/mobile/Dropdown";
import * as sc from "./FinCalcLightboxGroup.sc";
import Edit from "./rows/Edit";
import Label from "./rows/Label";
import List from "./rows/List";
import Radio from "./rows/Radio";
import Select from "./rows/Select";

export type ColumnsOrder = "label-value-unit" | "label-unit-value";
interface FinCalcLightboxGroupProps {
    group: GQLFinanceInformationGroupFragmentFragment;
    loading?: boolean;
}

const FinCalcLightboxGroup: React.FC<FinCalcLightboxGroupProps> = ({ group, loading }) => {
    const [isOpen, setIsOpen] = useStoredState<boolean>(
        `${group.label}-openState`,
        group.label === "Finanzierung" ||
            group.label === "Financing" ||
            group.label === "Kredit" ||
            group.label === "Credit" ||
            group.label === "Financiranje" ||
            group.label === "Finanszírozás",
        window.sessionStorage,
    );

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    };

    const domain = getConfig("scope").domain;
    const columnsOrder = domain === "car4me.porschefinance.hu" ? "label-value-unit" : "label-unit-value";

    return (
        <Dropdown
            header={
                <sc.DropdownHeader>
                    <sc.SubHeadline>{group.label}</sc.SubHeadline>
                    <sc.LabelWrapper>{!isOpen && getEmphasizedValue(group.parameters)}</sc.LabelWrapper>
                </sc.DropdownHeader>
            }
            open={isOpen}
            onHeaderClick={toggleIsOpen}
            disableMargin
            alignItems="baseline"
        >
            <sc.Section columnsOrder={columnsOrder}>
                {group.disclaimer && (
                    <sc.Disclaimer
                        columnsOrder={columnsOrder}
                        dangerouslySetInnerHTML={{
                            __html: group.disclaimer ?? "",
                        }}
                    />
                )}
                {group.parameters.map((parameter) => {
                    if (parameter.id === "ResidualValue") return;

                    switch (parameter.type) {
                        case "Label":
                            return (
                                <Label
                                    parameter={parameter}
                                    loading={loading}
                                    bold={domain === "car4me.si" || domain === "car4me.porschefinance.hu" ? parameter.id === "Rate" : false}
                                    columnsOrder={columnsOrder}
                                />
                            );
                        case "Edit":
                            return <Edit parameter={parameter} columnsOrder={columnsOrder} />;
                        case "Select": {
                            switch (parameter.selectType) {
                                case "Radio":
                                    return <Radio parameter={parameter} />;
                                case "List":
                                    if (parameter.dataType === "Decimal") {
                                        return <List parameter={parameter} columnsOrder={columnsOrder} />;
                                    }
                                    return <Select parameter={parameter} columnsOrder={columnsOrder} />;
                                default:
                                    return <Select parameter={parameter} columnsOrder={columnsOrder} />;
                            }
                        }
                    }
                })}
            </sc.Section>
        </Dropdown>
    );
};

function getEmphasizedValue(values: GQLFinanceInformationParameterFragmentFragment[]) {
    for (const value of values) {
        if ((value.bold && value.dataType === "Decimal") || (value.bold && value.id === "ServiceSum")) {
            return <Label parameter={value} bold={false} />;
        }
    }
    return <></>;
}

export default FinCalcLightboxGroup;
