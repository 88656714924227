import { Maybe } from "@src/graphql.apollo.generated";
import styled, { css } from "styled-components";

import { ColumnsOrder } from "./FinCalcLightboxGroup";

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
    box-sizing: border-box;
`;

export const ColumnWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
`;

interface ColumnProps {
    largeText?: Maybe<boolean>;
    bold?: Maybe<boolean>;
    isFullWidth?: boolean;
    hasError?: boolean;
    columnsOrder?: ColumnsOrder;
}

export const Column = styled.div<ColumnProps>`
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme, hasError }) => (hasError ? theme.colors.darkRed : theme.colors.porscheBankBlue)};
    width: ${({ isFullWidth = false }) => (isFullWidth ? "100%" : "auto")};
    display: flex;
    align-items: center;

    ${({ columnsOrder }) =>
        columnsOrder === "label-value-unit" &&
        css`
            justify-content: flex-end;
        `}

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 16px;

        ${({ largeText }) =>
            largeText &&
            `
            font-size: 18px;
        `}
    }

    ${({ largeText }) =>
        largeText &&
        `
        font-size: 16px;
        line-height: 30px;
    `}

    ${({ bold }) =>
        bold &&
        `
        font-weight: 700;
    `}
`;

export const InfoIconWrapper = styled.div`
    position: absolute;
    right: 0;
`;

interface UnitProps {
    hasError?: boolean;
    columnsOrder?: ColumnsOrder;
}

export const Unit = styled.div<UnitProps>`
    font-size: 14px;
    white-space: nowrap;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 16px;
    }
`;

interface UnitLabelProps {
    columnsOrder?: ColumnsOrder;
}

export const UnitLabel = styled.div<UnitLabelProps>`
    display: flex;
    flex-direction: ${({ columnsOrder }) => (columnsOrder === "label-value-unit" ? "row-reverse" : "row")};
    align-items: center;
`;

interface SelectContainerWrapperProps {
    flexStyle?: "row" | "column";
    selectStyle?: "radio";
}

export const SelectContainerWrapper = styled.div<SelectContainerWrapperProps>`
    display: flex;
    flex-direction: ${({ flexStyle = "column" }) => (flexStyle === "column" ? "column" : "row")};
    justify-content: space-between;
    width: 100px;

    ${({ flexStyle = "column" }) =>
        flexStyle === "row" &&
        `
        gap: 15px;
    `}

    ${({ selectStyle }) =>
        selectStyle === "radio" &&
        css`
            width: 100%;
            min-width: 100%;
            justify-content: flex-start;
            padding-top: 10px;
        `}

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        min-width: 200px;
        width: 100%;
    }
`;

export const Error = styled.div`
    color: ${({ theme }) => theme.colors.darkRed};
    padding-top: 10px;
    font-size: 14px;

    a {
        color: ${({ theme }) => theme.colors.darkRed};
        font-weight: 700;
        text-decoration: none;
    }

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 16px;
    }
`;

export const Root = styled.div`
    padding: 10px 0;
    margin-right: 30px;
`;
