import { GQLFinanceInformationParameter } from "@src/graphql.apollo.generated";
import * as React from "react";
import { Field } from "react-final-form";

import InfoIcon from "../../infoIcon/InfoIcon";
import { ColumnsOrder } from "../FinCalcLightboxGroup";
import { Column, ColumnWrapper, Error, InfoIconWrapper, SelectContainerWrapper, Unit } from "../formStyles.sc";
import Input from "./Input";

export interface EditProps {
    parameter: GQLFinanceInformationParameter;
    columnsOrder?: ColumnsOrder;
}

const Edit: React.FunctionComponent<EditProps> = ({ parameter, columnsOrder = "label-unit-value" }) => {
    const UnitContainer = (
        <Column>
            <Unit hasError={parameter.error != null}>{parameter.unit}</Unit>
        </Column>
    );

    const ValueContainer = (
        <Column columnsOrder={columnsOrder}>
            <SelectContainerWrapper>
                {parameter.defaultOption && parameter.id && (
                    <Field
                        name={parameter.id}
                        initialValue={parameter.defaultOption}
                        maxLength={Number(parameter.length) ?? 10}
                        component={Input}
                        hasError={parameter.error != null}
                        type={parameter.dataType === "Decimal" ? "number" : "text"}
                    />
                )}
            </SelectContainerWrapper>
            {parameter.help && (
                <InfoIconWrapper>
                    <InfoIcon alignment="right" infoText={<div dangerouslySetInnerHTML={{ __html: parameter.help }} />} isClickable />
                </InfoIconWrapper>
            )}
        </Column>
    );

    return (
        <>
            <div>
                <Column>{parameter.label}</Column>
                {parameter.error?.description && <Error dangerouslySetInnerHTML={{ __html: parameter.error?.description ?? "" }} />}
            </div>

            {columnsOrder === "label-unit-value" ? (
                <ColumnWrapper>
                    {parameter.unit && UnitContainer}
                    {ValueContainer}
                </ColumnWrapper>
            ) : (
                <>
                    {ValueContainer}
                    {parameter.unit && UnitContainer}
                </>
            )}
        </>
    );
};

export default Edit;
